/* this will be the component that allows user to start a new run */
import React, { useState } from "react";
import { FormGroup, Form, Label, Input, UncontrolledTooltip } from "reactstrap";

import { connect } from "react-redux";
import { startProject } from "redux/Project/thunks";
import { setLoginMessage } from "redux/Authentication/actions";
import { getNextAvailableRun } from "redux/Project/selectors";
import { closeModal } from "redux/Modal/ModalModule";
import {
  addSingleAlert,
  dismissAlertById
} from "redux/Notification/NotificationModule";
import RightMarginButton from "components/styled/RightMarginButton";

import { startProjectPassed } from "redux/Authentication/selectors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const StartRun = (props) => {
  const [selectedPlanId, setSelectedPlanId] = useState(0);
  const [runStarted, setRunStarted] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect((props) => {
  //   return () => {
  //     props.dismissAlertById(3);
  //   };
  // }, [props.dismissAlertById]);

  const handlePlanChange = (event) => {
    setSelectedPlanId(event.target.value);
  };

  const handleStartRun = () => {
    setLoading(true);
    setRunStarted(true);
    const alert = {
      id: 3,
      type: "info",
      headline: "Initializing new project",
      event: "startrun",
      message:
        "Please wait, initializing will take a few minutes.  When complete, you will be redirected to the planning module"
    };
    props.addAlert(alert, {}, 11000, false);
    const currentPlan = props.planList[selectedPlanId];
    Promise.all([
      props.startProject(
        props.nextAvailableRun,
        currentPlan.PlanId,
        currentPlan.PlanDescription
      )
    ]).then(() => {
      setLoading(false);
      if (props.startedByModal === true) {
        props.closeModal();
      }
    });
  };
  const { studentInfo, planList, nextAvailableRun, row } = props;

  // const pointerEvents = this.state.checklistComplete
  // 	? { pointerEvents: 'all' }
  // 	: { pointerEvents: 'none' };
  let seqNo =
    row && row.RunSequenceNumber !== null
      ? row.RunSequenceNumber
      : nextAvailableRun.runSequenceNumber;

  if (seqNo === null || seqNo === undefined) {
    seqNo = 1;
  }
  const groupId = `formControlsSelect-${seqNo ? seqNo.toString() : "1"}`;
  return (
    <div>
      <Form inline>
        {studentInfo &&
        studentInfo.StudentClassification &&
        (studentInfo.StudentClassification === 99 ||
          studentInfo.StudentClassification === 89) ? (
          <FormGroup id={groupId}>
            <Label for={`plan-${seqNo.toString()}`}>
              Select Plan (default is no plan)
            </Label>
            <Input
              id={`plan-${seqNo.toString()}`}
              type="select"
              placeholder="Select plan"
              value={selectedPlanId}
              onChange={(event) => handlePlanChange(event)}
            >
              {planList.map((row) => (
                <option
                  id={row.PlanId.toString()}
                  value={row.PlanId}
                  name="PlanOptions"
                  key={row.PlanId}
                >
                  {row.PlanDescription}
                </option>
              ))}
            </Input>
          </FormGroup>
        ) : (
          <span />
        )}
        <UncontrolledTooltip
          id="starttt"
          placement="top"
          target={`start-${seqNo.toString()}`}
          // delay={{ hide: 900 }}
        >
          Start a new project
        </UncontrolledTooltip>

        <span>
          <RightMarginButton
            id={`start-${seqNo.toString()}`}
            color="primary"
            onClick={(event) => handleStartRun(event)}
            label="Start New Project"
            disabled={props.startProjectPassed === false || runStarted === true}
          >
            {!loading && "Start"}

            {loading && (
              <FontAwesomeIcon
                icon={faSpinner}
                className="fa-pulse load-icon"
              />
            )}
          </RightMarginButton>
        </span>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  runId: state.project.runId,
  studentInfo: state.auth.studentInfo,
  runDetails: state.auth.runDetails,
  planList: state.auth.planList,
  nextAvailableRun: getNextAvailableRun(state),
  startProjectPassed: startProjectPassed(state)
});

const mapDispatchToProps = (dispatch) => ({
  startProject: (studentData, planId, planDescription) =>
    dispatch(startProject(studentData, planId, planDescription)),
  setLoginMessage: (payload) => dispatch(setLoginMessage(payload)),
  closeModal: () => dispatch(closeModal()),
  addAlert: (alert, notificationData, timeout, shouldPersist) =>
    dispatch(addSingleAlert(alert, notificationData, timeout, shouldPersist)),
  dismissAlertById: (idx) => dispatch(dismissAlertById(idx))
});

const StartRunContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StartRun);
export default StartRunContainer;
