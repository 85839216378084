export const PROCESS_ASSIGNMENTS_SUCCESS = "PROCESS_ASSIGNMENTS_SUCCESS";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const SET_RUNID = "SET_RUNID";
export const RECEIVE_API_SUPPORT_DATA = "RECEIVE_API_SUPPORT_DATA";
export const REQUESTING_DATA = "REQUESTING_DATA";
export const DATA_RECEIVED = "DATA_RECEIVED";
export const RECEIVE_PROJECT_DATA = "RECEIVE_PROJECT_DATA";
export const PROJECT_READY = "PROJECT_READY";
export const RECEIVE_WORKER_DATA = "RECEIVE_WORKER_DATA";
export const RECEIVE_SKILL_DATA = "RECEIVE_SKILL_DATA";
export const REFRESH_POST_RUNDAY = "REFRESH_POST_RUNDAY";
export const RECEIVE_TASK_DATA = "RECEIVE_TASK_DATA";
export const RECEIVE_NEW_PROJECT = "RECEIVE_NEW_PROJECT";
export const SET_BUDGET_LOCKED = "SET_BUDGET_LOCKED";
export const RECEIVE_BUDGET = "RECEIVE_BUDGET";
export const UPDATE_BUDGET_SUCCESS = "UPDATE_BUDGET_SUCCESS";
export const TOGGLE_STAFFING_ACCEPTED = "TOGGLE_STAFFING_ACCEPTED";
export const BUMP_RUN_SUCCESS = "BUMP_RUN_SUCCESS";
export const COPY_RUN_SUCCESS = "COPY_RUN_SUCCESS";
export const RECEIVE_WORKERASSIGNMENTS_SUCCESS =
  "RECEIVE_WORKERASSIGNMENTS_SUCCESS";
export const RECEIVE_WORKERASSIGNMENTS_ERROR =
  "RECEIVE_WORKERASSIGNMENTS_ERROR";
export const ACCEPT_WEEKLYSTAFFING_SUCCESS = "ACCEPT_WEEKLYSTAFFING_SUCCESS";
export const UPDATE_PROJECT_STATUS = "UPDATE_PROJECT_STATUS";
export const COMMUNICATION_MARKED_AS_READ = "COMMUNICATION_MARKED_AS_READ";
export const SET_READONLYPROJECT = "SET_READONLYPROJECT";
export const SET_NEWPROJECTSTARTED = "SET_NEWPROJECTSTARTED";
export const CLEAR_PROJECT = "CLEAR_PROJECTs";
export const CHECKLIST_UPDATED = "CHECKLIST_UPDATED";
