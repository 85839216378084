import React, { useState } from "react";
import { connect } from "react-redux";
import { copyProject } from "redux/Project/thunks";
import RightMarginButton from "components/styled/RightMarginButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const CopyRun = props => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <RightMarginButton
        color="primary"
        onClick={async () => {
          setLoading(true);
          await props.copyProject(props.runId);
          setLoading(false); // Reset loading to false
        }}
      >
        {!loading && "Copy"}
        {loading && (
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse load-icon" />
        )}
      </RightMarginButton>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  copyProject: (runId) =>
    dispatch(copyProject(runId))
});

const CopyRunContainer = connect(mapStateToProps, mapDispatchToProps)(CopyRun);
export default CopyRunContainer;
