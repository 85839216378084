import { handleActions } from "redux-actions";
import {
  SET_NEWPROJECTSTARTED,
  SET_READONLYPROJECT,
  COMMUNICATION_MARKED_AS_READ,
  SET_RUNID,
  COPY_RUN_SUCCESS,
  BUMP_RUN_SUCCESS,
  CLEAR_PROJECT,
  RECEIVE_PROJECT_DATA,
  RECEIVE_WORKER_DATA,
  RECEIVE_SKILL_DATA,
  RECEIVE_TASK_DATA,
  RECEIVE_WORKERASSIGNMENTS_SUCCESS,
  PROCESS_ASSIGNMENTS_SUCCESS,
  TOGGLE_STAFFING_ACCEPTED,
  ACCEPT_WEEKLYSTAFFING_SUCCESS,
  PROJECT_READY,
  SET_BUDGET_LOCKED,
  REFRESH_POST_RUNDAY,
  RECEIVE_BUDGET,
  // UPDATE_BUDGET_SUCCESS,
  PROJECT_ERROR,
  REQUESTING_DATA,
  DATA_RECEIVED,
  CHECKLIST_UPDATED,
  UPDATE_PROJECT_STATUS
} from "./types";

 const getOvertimeItems = () => {
    const ot = [];
    ot.push({
      value: 1,
      text: "1 hour"
    });

    for (let i = 2; i <= 20; i++) {
      const t = i.toString();
      const b = t.concat(" hours");
      ot.push({ value: i, text: b });
    }

    return ot;
 }

const initialState = {
  daysOfWeek: [],
  runId: 0,
  isFetching: false,
  workers: [],
  currentWorkerList: [],
  tasks: [],
  resourceActionTasks: [],
  stakeholderList: [],
  stakeholderCourseList: [],
  stakeholderActionList: [],
  meetingTopicList: [],
  overtimeItems: [...getOvertimeItems()],
  meetingDurationList: [
    { id: 0, duration: 0.5 },
    { id: 1, duration: 1.0 },
    { id: 2, duration: 1.5 },
    { id: 3, duration: 2.0 },
    { id: 4, duration: 2.5 },
    {id: 5, duration: 3.0}
  ],
  conferenceTopicList: [],
  feedbackList: [],
  conferenceDurationList: [
    { id: 0, duration: 1.0 },
    { id: 1, duration: 2.0 },
    {id: 2, duration: 3.0}
  ],
  rewardList: [],
  skills: [],
  maxWeeks: 25,
  runDay: 0,
  currentWeekDayNo: "",
  currentProjectWeek: 0,
  displayProjectWeek: 0,
  lastCompletedWeek: 0,
  projectedFinishWeek: 0,
  totalBudgetSpent: 0,
  budgetIsLocked: false,
  budgetList: [],
  budgetFixed: [],
  workerAssignmentList: [],
  taskStatusList: [],
  nodeNetwork: [],
  taskSkillList: [],
  communicationList: [],
  projectControlStatusList: [],
  workerFixedList: [],
  weeklyInteractions: [],
  trainingList: [],
  weeklyStaffingAccepted: false,
  projectStatus: {},
  error: "",
  projectReadOnly: false,
  newProjectStarted: false,
  projectCheckList: []
  //  currentTrainings: [], // need this here for use in both planning and execution
};

// utility function to get current week from projectstatus
const getCurrentWeek = (projectStatus) => {
  let week = 1;

  if(projectStatus.DayNumber > 0 ) {
     // it's monday after the runweek, the day is still set to friday's day number until the run starts
       // this way, we know we need to add a 1 to the weeknumber for now
      week = Math.floor((projectStatus.DayNumber - 1)/5) + 1

     if (projectStatus.DayNumber % 5 === 0 && projectStatus.WeekHasCompleted === true) {

         week += 1;
     }
  }
  return week;
}


// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [REQUESTING_DATA]: state => ({
      ...state,
      isFetching: true
    }),
    [DATA_RECEIVED]: state => ({
      ...state,
      isFetching: false
    }),
    [SET_RUNID]: (state, { payload }) => ({
      ...state,
      runId: payload
    }),
    [COPY_RUN_SUCCESS]: (state, { payload }) => ({
      ...initialState
    }),
    [BUMP_RUN_SUCCESS]: (state, { payload }) => ({
      ...initialState
    }),
    [RECEIVE_PROJECT_DATA]: (state, { payload }) => {
      // var daysofweek = payload.DayOfWeekList
      const projDataLists = payload.projectDataLists;
      const currentWeek = getCurrentWeek(projDataLists.projectStatus);
      return {
        ...state,
        isFetching: false,
        // projDataLists.projectStatus.DayNumber > 0 &&
        // projDataLists.projectStatus.WeekHasCompleted === false
        //   ? projDataLists.currentProjectWeek - 1
        //   : projDataLists.currentProjectWeek,
        runDay: projDataLists.currentProjectDay,
        currentDayOfWeek: projDataLists.projectStatus.DayNumber,
        //'projDataLists.currentDayOfWeek,
        workerAssignmentList: projDataLists.workerAssignmentList,
        taskStatusList: projDataLists.taskStatusList,
        nodeNetwork: projDataLists.nodeNetwork,
        projectedFinishWeek: projDataLists.projectFinishWeek, // needs to be fixed for right field
        totalBudgetSpent: projDataLists.totalBudgetSpent,
        budgetIsLocked: projDataLists.budgetIsLocked,
        budgetList: projDataLists.budgetList,
        communicationList: projDataLists.communicationList,
        projectControlStatusList: projDataLists.projectControlStatusList,
        workers: projDataLists.workerList,
        //  history: projDataLists.projectHistoryList,
        resourceActionTasks: projDataLists.resourceTaskActionList,
        projectStatus: projDataLists.projectStatus,
        currentProjectWeek: currentWeek,
        displayProjectWeek: currentWeek,
        workerFixedList: projDataLists.allWorkerFixedList,
        weeklyInteractions: projDataLists.weeklyInteractions,
        projectCheckList: projDataLists.projectCheckList
      };
    },

    [PROJECT_READY]: (state, { payload }) => {

      const projDataLists = payload.projectDataLists;
      const projLOVLists = payload.projectLOVLists;
      const currentWeek = getCurrentWeek(projDataLists.projectStatus);
      return {
        ...state,
        isFetching: false,
        runId: payload.runId,
        projectCheckList: projDataLists.projectCheckList,
        // projDataLists.projectStatus.DayNumber > 0 &&
        // projDataLists.projectStatus.WeekHasCompleted === false
        //   ? projDataLists.currentProjectWeek - 1
        //   : projDataLists.currentProjectWeek,
        runDay: projDataLists.currentProjectDay,
        currentWeekDayNo: projDataLists.currentWeekDayNo,
        workerAssignmentList: projDataLists.workerAssignmentList,
        taskStatusList: projDataLists.taskStatusList,
        nodeNetwork: projDataLists.nodeNetwork,
        projectedFinishWeek: projDataLists.projectFinishWeek, // needs to be fixed for right field
        totalBudgetSpent: projDataLists.totalBudgetSpent,
        budgetIsLocked: projDataLists.budgetIsLocked,
        budgetList: projDataLists.budgetList,
        daysOfWeek: projLOVLists.dayOfWeekList,
        trainingList: projLOVLists.availableTraining,
        stakeholderList: projLOVLists.stakeholderList,
        stakeholderCourseList: projLOVLists.stakeholderCourseList,
        stakeholderActionList: projLOVLists.stakeholderActionList,
        meetingTopicList: projLOVLists.meetingTopicList,
        feedbackList: projLOVLists.feedbackList,
        conferenceTopicList: projLOVLists.conferenceTopicList,
        conferenceDurationList: projLOVLists.conferenceDurationList,
        // meetingDurationList : [...state.meetingDurationList],
        rewardList: projLOVLists.rewardList,
        tasks: projLOVLists.taskList,
        taskSkillList: projLOVLists.taskSkillList,
        resourceActionTasks: projDataLists.resourceTaskActionList,
        skills: projLOVLists.skillList,
        communicationList: projDataLists.communicationList,
        projectControlStatusList: projDataLists.projectControlStatusList,
        //  history: projDataLists.projectHistoryList,
        workers: projDataLists.workerList,
        workerFixedList: projDataLists.allWorkerFixedList,
        weeklyInteractions: projDataLists.weeklyInteractions,
        projectStatus: projDataLists.projectStatus,
        currentProjectWeek: currentWeek,
        displayProjectWeek: currentWeek
      };
    },
    [TOGGLE_STAFFING_ACCEPTED]: (state, { payload }) => ({
      ...state,
      weeklyStaffingAccepted: payload
    }),
    [RECEIVE_BUDGET]: (state, { payload }) => ({
      ...state,
      budgetList: [...payload]
    }),
    [UPDATE_PROJECT_STATUS]: (state, { payload }) => {

      const currentWeek = getCurrentWeek(payload);

      return {
      ...state,
      projectStatus: payload,
           currentProjectWeek: currentWeek,
        displayProjectWeek: currentWeek
      }
    },
    // [REFRESH_POST_RUNWEEK]: (state, {payload}) => {
    //   var weekReport = payload.interimHistory[0]
    //   return {
    //     ...state,
    //     workerAssignmentList: payload.workerAssignmentList,
    //     communicationList: payload.communicationList,
    //     runDay: payload.currentProjectDay,
    //     currentProjectWeek: payload.currentProjectWeek - 1,
    //     displayProjectWeek: payload.currentProjectWeek,
    //     history: [
    //       ...state.history,
    //       weekReport
    //     ]
    //   }
    // },
    [REFRESH_POST_RUNDAY]: (state, { payload }) => {

     const currentWeek = getCurrentWeek(payload.projectStatus);


      return {

      ...state,
      runDay: payload.currentProjectDay,
      projectStatus: payload.projectStatus,
      currentProjectWeek: currentWeek,
      displayProjectWeek:currentWeek
      }

    },
    [RECEIVE_WORKER_DATA]: (state, { payload }) =>
      // var daysofweek = payload.DayOfWeekList
      ({
        ...state,
        workers: payload.workers
      }),
    [RECEIVE_SKILL_DATA]: (state, { payload }) =>
      // var daysofweek = payload.DayOfWeekList
      ({
        ...state,
        skills: payload.skills
      }),
    [RECEIVE_TASK_DATA]: (state, { payload }) =>
      // var daysofweek = payload.DayOfWeekList
      ({
        ...state,
        tasks: payload.tasks
      }),
    [RECEIVE_WORKERASSIGNMENTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      workerAssignmentList: payload
    }),
    [ACCEPT_WEEKLYSTAFFING_SUCCESS]: (state, { payload }) => {
      const newAssignments = payload;
      return {
        ...state,
        workerAssignmentList: newAssignments
      };
    },
    [PROCESS_ASSIGNMENTS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        workerAssignmentList: [...payload]
      };
    },
    [COMMUNICATION_MARKED_AS_READ]: (state, { payload }) => {
      return  ({
          ...state,
        communicationList: { ...payload }
        })
    },
    [SET_BUDGET_LOCKED]: state => ({
      ...state,
      budgetIsLocked: true
    }),
    [SET_READONLYPROJECT]: (state, { payload }) => ({
      ...state,
      projectReadOnly: payload
    }),
    [SET_NEWPROJECTSTARTED]: (state, { payload }) => ({
      ...state,
      newProjectStarted: payload
    }),
    [PROJECT_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [CLEAR_PROJECT]: () => ({
      ...initialState
    }),
    [CHECKLIST_UPDATED]: (state, { payload }) => ({
      ...state,
      projectCheckList: payload
    })
  },
  initialState
);
