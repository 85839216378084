import React from "react";
import { connect } from "react-redux";
import { getEnhancedBudgetList } from "redux/Project/selectors";
import accounting from "accounting";
import { nextUntil } from "utilities/utils";

require("./Budget.css");
require("static/css/hotfix-styles.css");

// Polyfill to deal with IE issue
// which doesn't support forEach on NodeLists
(function () {
  if (typeof NodeList.prototype.forEach === "function") return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
})();

// for the accounting package display helper - see
// https://www.npmjs.com/package/accounting
// used to help display money values
const options = {
  symbol: "$",
  decimal: ".",
  thousand: ",",
  precision: 0,
  format: "%s%v"
};

export class BudgetContainer extends React.Component {
  state = {
    selectedRows: []
  };

  renderPlannedBudgetField(item, plannedCost) {
    if (this.props.budgetIsLocked === true) {
      return (
        <td className="amountCell text-center">
          <div>{accounting.formatMoney(plannedCost, options)}</div>
        </td>
      );
    }
    return (
      <td className="amountCell text-center">
        <div>{accounting.formatMoney(plannedCost, options)}</div>
      </td>
    );
  }

  componentDidMount() {
    this.addtoggleCarets();
  }

  addtoggleCarets() {
    const rowHeaderEls = document.querySelectorAll(".row-Header");
    rowHeaderEls.forEach((row) => {
      const childRows = nextUntil(row, ".row-Header");
      if (childRows.length > 0) {
        row.querySelector(".fa").classList.add("fa-caret-right");
      }
    });
  }

  toggleRow(e) {
    const rowHeaderEl = e.currentTarget;
    if (rowHeaderEl.classList.contains("row-Header")) {
      const iconEl = rowHeaderEl.querySelector(".fa");
      const childRows = nextUntil(rowHeaderEl, ".row-Header");
      if (childRows.length > 0) {
        // Toggle caret icon
        iconEl.classList.toggle("fa-caret-down");
        iconEl.classList.toggle("fa-caret-right");
        // Toggle expanded class on childRows
        childRows.forEach((row) => {
          row.classList.toggle("expanded");
        });
      }
    }
  }

  getRowFormatting = (type) => {
    if (type === "1") {
      return "node1Cell";
    }
    if (type === "2") {
      return "node2Cell";
    }
    return "titleCell";
  };

  render() {
    let iterKey = 9000;
    const { enhancedBudgetList } = this.props;

    const sortedData = enhancedBudgetList.sort((a, b) => {
      const numA = parseFloat(a.PrefixDescriptor);
      const numB = parseFloat(b.PrefixDescriptor);
      return numA - numB;
    });

    return (
      <table
        className="table table-hover table-striped table-FullBudget"
        tourstop="budget"
      >
        <thead>
          <tr>
            <th>WBS Item</th>
            <th>Task</th>
            <th>Approved</th>
            <th>Planned</th>
            <th>Actual</th>
            <th>Variance</th>
          </tr>
        </thead>
        <tbody id="budget-report">
          {sortedData.map((row, index) => (
            <tr
              key={(iterKey += 1)}
              selected={this.state.selectedRows.indexOf(index) !== -1}
              className={row.Formatting !== "2" ? "row-Header" : ""}
              onClick={(e) => this.toggleRow(e)}
            >
              <td className={this.getRowFormatting(row.Formatting)}>
                <div>
                  {row.Formatting !== "2" ? <i className="fa" /> : ""}
                  {row.PrefixDescriptor}
                </div>
              </td>
              <td className="actionCell text-center">
                <div>{row.DisplayTask}</div>
              </td>
              <td className="amountCell  text-center">
                <div>{accounting.formatMoney(row.ApprovedBudget, options)}</div>
              </td>
              {this.renderPlannedBudgetField(row, row.PlannedCost)}
              <td className="amountCell  text-center">
                <div>{accounting.formatMoney(row.ActualCost, options)}</div>
              </td>
              <td
                className={
                  row.Difference >= 0
                    ? "amountCell  text-center"
                    : "negamountCell  text-center"
                }
              >
                <div>
                  {accounting.formatMoney(
                    row.PlannedCost - row.ActualCost,
                    options
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => ({   
  enhancedBudgetList: getEnhancedBudgetList(state),
  isFetching: state.project.isFetching,
  budgetIsLocked: state.project.budgetIsLocked,
  runId: state.project.runId
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetContainer);
