import { createAction } from "redux-actions";
import {
  SET_NEWPROJECTSTARTED,
  SET_READONLYPROJECT,
  COMMUNICATION_MARKED_AS_READ,
  SET_RUNID,
  COPY_RUN_SUCCESS,
  BUMP_RUN_SUCCESS,
  RECEIVE_API_SUPPORT_DATA,
  RECEIVE_PROJECT_DATA,
  RECEIVE_WORKER_DATA,
  RECEIVE_SKILL_DATA,
  RECEIVE_TASK_DATA,
  RECEIVE_WORKERASSIGNMENTS_SUCCESS,
  PROCESS_ASSIGNMENTS_SUCCESS,
  TOGGLE_STAFFING_ACCEPTED,
  ACCEPT_WEEKLYSTAFFING_SUCCESS,
  PROJECT_READY,
  SET_BUDGET_LOCKED,
  REFRESH_POST_RUNDAY,
  RECEIVE_BUDGET,
  UPDATE_BUDGET_SUCCESS,
  PROJECT_ERROR,
  REQUESTING_DATA,
  DATA_RECEIVED,
  CLEAR_PROJECT,
  CHECKLIST_UPDATED,
  UPDATE_PROJECT_STATUS
} from "./types";

export const setNewProjectStarted = createAction(
  SET_NEWPROJECTSTARTED,
  payload => payload
);

export const checkListUpdated = createAction(
  CHECKLIST_UPDATED,
  payload => payload
);

export const clearProject = createAction(CLEAR_PROJECT);
export const setProjectAsReadOnly = createAction(
  SET_READONLYPROJECT,
  payload => payload
);
export const communicationRead = createAction(
  COMMUNICATION_MARKED_AS_READ,
  payload => payload
);
export const setRunId = createAction(SET_RUNID, payload => payload);
export const copyRunSuccess = createAction(
  COPY_RUN_SUCCESS,
  payload => payload
);
export const bumpRunSuccess = createAction(
  BUMP_RUN_SUCCESS,
  payload => payload
);
export const receiveAPISupportData = createAction(
  RECEIVE_API_SUPPORT_DATA,
  payload => payload
);
export const updateProjectStatus = createAction(UPDATE_PROJECT_STATUS, payload => payload);

export const requestingData = createAction(REQUESTING_DATA);
export const dataReceived = createAction(DATA_RECEIVED);
export const receiveProjectData = createAction(
  RECEIVE_PROJECT_DATA,
  payload => payload
);
export const receiveWorkerData = createAction(
  RECEIVE_WORKER_DATA,
  payload => payload
);
export const receiveSkillData = createAction(
  RECEIVE_SKILL_DATA,
  payload => payload
);
export const receiveTaskData = createAction(
  RECEIVE_TASK_DATA,
  payload => payload
);
export const receiveAssignments = createAction(
  RECEIVE_WORKERASSIGNMENTS_SUCCESS,
  payload => payload
);
export const processAssignmentsSuccess = createAction(
  PROCESS_ASSIGNMENTS_SUCCESS,
  payload => payload
);
export const toggleWeeklyStaffingAccepted = createAction(
  TOGGLE_STAFFING_ACCEPTED,
  payload => payload
);
export const acceptWeeklyStaffingSuccess = createAction(
  ACCEPT_WEEKLYSTAFFING_SUCCESS,
  payload => payload
);

export const projectResumed = createAction(PROJECT_READY, payload => payload);
export const projectStarted = createAction(PROJECT_READY, payload => payload);
export const setBudgetIsLocked = createAction(SET_BUDGET_LOCKED);
export const refreshAfterRunDay = createAction(
  REFRESH_POST_RUNDAY,
  payload => payload
); // no payload, increase day
export const budgetReceived = createAction(RECEIVE_BUDGET, payload => payload);
export const actionError = createAction(PROJECT_ERROR, payload => payload);

export const updateBudgetSuccess = createAction(
  UPDATE_BUDGET_SUCCESS,
  payload => payload
);
