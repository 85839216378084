import React from "react";
import { connect } from "react-redux";
import StartRunContainer from "./StartRun.jsx";
import ResumeRun from "./ResumeRun";
import BumpRun from "./BumpRun";
import ResetRun from "./ResetRun";
import CopyRun from "./CopyRun";
import ReviewRun from "./ReviewRun";
import { resumeProject, reviewProject } from "redux/Project/thunks";
import { fetchRuns } from "redux/Authentication/thunks";

const RunActionButton = (props) => {
  const isAdminUser = () => {
    return Number.parseInt(props.classification, 10) !== Number.parseInt(0, 10);
  };

  const renderChildren = (row) => {
    const runStatus = row.RunStatus;
    const buttonList = [];  
    if (
      runStatus.substring(0, 3) === "Run" ||
      runStatus.substring(0, 3) === "App" ||
      runStatus.substring(0, 3) === "Pla"
    ) {
      buttonList.push(
        <ResumeRun
          key="resume-run"
          runId={row.RunId}
          productId={row.ProductId}
          resumeProject={props.resumeProject}
        />
      );
    } else if (
      runStatus.substring(0, 3) === "Com" ||
      runStatus.substring(0, 3) === "Aba"
    ) {
      // completed/ abandoned
      buttonList.push(
        <ReviewRun
          key="review-run"
          runId={row.RunId}
          productId={row.ProductId}
          reviewProject={props.reviewProject}
        />
      );
    } else if (
      runStatus.substring(0, 3) !== "Not" ||
      (isAdminUser(row) && row.RunId === 0)
    ) {
      buttonList.push(<StartRunContainer row={row} key="start-run" />);
    }

    if (isAdminUser() && row.RunId > 0) {
      buttonList.push(
        <BumpRun
          key="bump-run"
          classStudentRunId={row.ClassStudentRunDBId}
          runId={row.RunId}
        />
      );
      buttonList.push(
        <ResetRun key="reset-run" runId={row.RunId} finalWeek={row.FinalWeek} />
      );
      buttonList.push(
        <CopyRun key="copy-run" runId={row.RunId} finalWeek={row.FinalWeek} />
      );
    }
    return buttonList;
  };

  return <div id={"a1"}>{renderChildren(props.thisRun)}</div>;
};

const mapStateToProps = (state) => ({
  licenseId: state.auth.studentInfo.LicenseId,
  productId: state.auth.studentInfo.ProductId,
  classification: state.auth.studentInfo.StudentClassification,
});

const mapDispatchToProps = (dispatch) => ({
  resumeProject: (runId, productId) =>
    dispatch(resumeProject(runId, productId)),
  reviewProject: (runId, productId) => dispatch(reviewProject(runId, productId)),
  fetchRuns: userId => dispatch(fetchRuns(userId))
});

const RunActionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RunActionButton);
export default RunActionsContainer;
