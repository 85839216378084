import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Collapse
} from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import { getNextAvailOrCurrentRun } from "redux/Project/selectors";
import { fetchRuns } from "redux/Authentication/thunks";
import ExecutionList from "./components/ExecutionList";
import ProjectInfo from "./components/ProjectInfo";

const ManageProjects = (props) => {
  const [cardInfoOpen, setCardInfoOpen] = useState(false);
  const [runsFetched, setRunsFetched] = useState(false); // Track if runs are already fetched

  useEffect(() => {
    if (!runsFetched) {
      props.fetchRuns(props.auth.userId);
      setRunsFetched(true); // Mark runs as fetched
    }
  }, [props, runsFetched]);

  return (
    <>
      <Row>
        <Col lg="12">
          <Card className="Card-info">
            <CardHeaderBold>
              <span style={{ marginRight: "10px" }}>Project Info</span>
              <Label className="switch switch-default switch-primary">
                <Input
                  type="checkbox"
                  className="switch-input"
                  onClick={() => setCardInfoOpen(!cardInfoOpen)}
                  defaultChecked={cardInfoOpen}
                />
                <span className="switch-label" />
                <span className="switch-handle" />
              </Label>
            </CardHeaderBold>
            <Collapse isOpen={cardInfoOpen}>
              <ProjectInfo />
            </Collapse>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Col>
            <Card>
              <CardHeaderBold>Review/Manage Projects</CardHeaderBold>
              {props.auth.message !== "" &&
                props.auth.message !== "authenticated" && (
                  <span>
                    <br />
                    <br />
                    <ul>{props.auth.message}</ul>
                  </span>
                )}
              <CardBody>
                <ExecutionList runDetails={props.runDetails} />
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  studentInfo: state.auth.studentInfo,
  runDetails: state.auth.runDetails,
  currentRun: getNextAvailOrCurrentRun(state),
  modalOpen: state.modal.open
});

const mapDispatchToProps = dispatch => ({
  fetchRuns: userId => dispatch(fetchRuns(userId))
});

export const ManageProjectsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProjects);
